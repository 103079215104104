import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AcceptBuyout, GetById, GetItems } from '../../../../../services/buyouts'
import { Buyout } from '../../../../../services/buyouts/types'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'
import { RejectionBuyoutModal as RejectionBuyoutModal } from './rejection'
import { BuyoutHeader } from '../common/buyoutHeader'
import { Item } from '../../../../../services/items/types'
import { Column } from '../../../../common/table/types'
import { Table } from '../../../../common/table'
import { SelectedOfferType } from '../../../../../services/generated'
import { buyoutStatus } from '../../../../../services/buyouts/mapper'
import {
  SButton,
  SButtonDanger,
  SDiv,
  SHR,
  SLabel,
  STitle,
} from '../../../../../theme/commonComponents'

export const SingleBuyView = (): JSX.Element => {
  const [buyout, setBuyout] = useState<Buyout>()
  const [items, setItems] = useState<Item[]>([])
  const [denyOffer, setDenyOffer] = useState<boolean>(false)
  const [onLoading, setOnLoading] = useState<boolean>(false)
  let { buyoutId } = useParams()

  const getBuyout = (): void => {
    try {
      GetById(buyoutId ?? '').then((data) => {
        setBuyout(data)
      })

      GetItems(buyoutId ?? '', undefined, undefined, undefined, undefined, 'pwe', 'ASC').then(
        (data) => {
          setItems(data)
        },
      )
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  useEffect((): void => {
    getBuyout()
  }, [])

  useEffect((): void => {
    if (onLoading) {
      getBuyout()
      setDenyOffer(false)
      setOnLoading(false)
    }
  }, [onLoading])

  // TODO: fetch deny buyout reasons
  const handleAcceptBuyout = (offerType: SelectedOfferType): void => {
    try {
      AcceptBuyout(buyoutId ?? '', offerType).then(() => {
        setOnLoading(true)
      })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('error message: ', error.message)
      } else {
        console.log('unexpected error:', error)
      }
    }
  }

  const handleDenyBuyout = (): void => {
    setDenyOffer(true)
  }

  const handleCancelDenyBuyout = (): void => {
    setDenyOffer(false)
  }

  const buildItemsTable = (): JSX.Element => {
    const columns: Column[] = [
      { title: 'Id', fieldName: 'id', hidden: true, sortable: false },
      { title: 'Name', fieldName: 'name', sortable: false },
      // { title: 'Picture', fieldName: 'imageUrl', sortable: true, isImage: true },
    ]

    return (
      <Table
        columns={columns}
        data={items}
        page={0}
        perPage={0}
        setPage={(): void => {}}
        totalRecords={0}
        hidePagination
      />
    )
  }

  const buildModal = () => {
    return (
      <RejectionBuyoutModal
        buyout={buyout ?? ({} as Buyout)}
        handleCancelDenyBuyout={handleCancelDenyBuyout}
        setOnLoading={setOnLoading}
        show={denyOffer}
        setShow={setDenyOffer}
      />
    )
  }

  const buildRejectionDetails = (): JSX.Element => {
    return (
      <>
        <div className='row mb-3'>
          <div className='col'>
            <SLabel htmlFor='rejectionReason'>Rejection reason</SLabel>
            <input
              className='form-control form-control-sm'
              type='text'
              id='rejectionReason'
              value={buyout?.rejection?.type.description}
              disabled
            />
          </div>
          {buyout?.rejectionText ? (
            <div className='col'>
              <SLabel htmlFor='rejectionReasonText'>Details</SLabel>
              <input
                type='text'
                className='form-control form-control-sm'
                id='rejectionReasonText'
                value={buyout?.rejectionText}
                disabled
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <SDiv className='d-flex flex-column p-4 pt-3 mb-5 h-100'>
        <BuyoutHeader buyout={buyout ?? ({} as Buyout)} />

        <div className='row mb-3 mt-5'>
          <div className='col-12'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
              <STitle>Summary</STitle>
            </div>
            <SHR />
          </div>
        </div>
        <div className='row mb-5'>
          <div className='col-sm-4 col-12 col-lg-4'>
            <SLabel htmlFor='storeCreditOfferTotal'>Store Credit Offer</SLabel>
            <input
              className='form-control form-control-sm'
              type='number'
              id='storeCreditOfferTotal'
              value={items?.reduce((sum, value) => sum + value.storeCreditOffer, 0)}
              disabled
            />
          </div>
          <div className='col'>
            {buyout?.rejection !== undefined ? (
              ''
            ) : buyout?.rejection === undefined &&
              buyout?.status !== buyoutStatus.CUSTOMER_APPROVED &&
              buyout?.status !== buyoutStatus.COMPLETED ? (
              <div className='d-flex justify-content-start h-100 align-items-end'>
                <SButtonDanger id='denyBuyout' className='me-4' onClick={handleDenyBuyout}>
                  Reject
                </SButtonDanger>
                <SButton
                  id='acceptStoreCredit'
                  onClick={(): void => handleAcceptBuyout(SelectedOfferType.STORE_CREDIT)}
                >
                  Accept
                </SButton>
              </div>
            ) : (
              ''
            )}
            {buyout?.rejection !== undefined ? buildRejectionDetails() : ''}
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-6 col-12 col-lg-6'>
            <SLabel htmlFor='numbItemsAccepted'>Number of items accepted</SLabel>
            <input
              className='form-control form-control-sm'
              type='number'
              id='numbItemsAccepted'
              value={items?.length}
              disabled
            />
          </div>
          <div className='col-sm-6 col-12 col-lg-6'>
            <SLabel htmlFor='status'>Number of items rejected</SLabel>
            <input
              className='form-control form-control-sm'
              type='number'
              id='numbItemsRejected'
              value={(buyout?.receivedQuantity ?? 0) - (items?.length ?? 0)}
              disabled
            />
          </div>
        </div>

        {/*TODO: DO NOT REMOVE, TO BE IMPLEMENTED*/}
        {/*<div className='row mb-3'>
          <label htmlFor='cashOfferTotal' className='col-sm-3'>
            Cash Offer Total
          </label>
          <div className='col-sm-3'>
            <input
              type='number'
              id='cashOfferTotal'
              value={items?.reduce((sum, value) => sum + value.cashOffer, 0)}
              disabled
            />
          </div> 
          {buyout?.rejection === undefined &&
          buyout?.status !== buyoutStatus.CUSTOMER_APPROVED &&
          buyout?.status !== buyoutStatus.COMPLETED ? (
            <div className='col-sm-6'>
              <ButtonContainer>
                <button
                  className='btn btn-primary'
                  id='acceptCashOffer'
                  onClick={(): void => handleAcceptBuyout(SelectedOfferType.CASH)}
                >
                  Accept
                </button>
              </ButtonContainer>
            </div>
          ) : (
            ''
          )}
        </div>*/}

        <div className='d-flex flex-row justify-content-between align-items-center mt-5'>
          <STitle>Items</STitle>
        </div>
        <SHR />
        <div className='mt-4 flex-fill bg-white rounded p-3'>{buildItemsTable()}</div>
      </SDiv>
      {denyOffer ? buildModal() : ''}
    </>
  )
}
