/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum NotificationTypeEnum {
    CUSTOMER_NEW_BUYOUT_CREATED = 'Customer_NewBuyoutCreated',
    CUSTOMER_READY_TO_REVIEW = 'Customer_ReadyToReview',
    CUSTOMER_ACCEPTED = 'Customer_Accepted',
    CUSTOMER_REJECTED = 'Customer_Rejected',
    STORE_READY_TO_REVIEW = 'Store_ReadyToReview',
    STORE_CUSTOMER_ACCEPTED = 'Store_CustomerAccepted',
    STORE_CUSTOMER_REJECTED = 'Store_CustomerRejected',
}
