import client from '../api/configs'
import { PaginatedData } from '../common/types'
import {
  AddBuyoutDTO,
  BuyoutDTO,
  ItemDTO,
  RejectDTO,
  RejectionTypeDTO,
  SelectedOfferType,
  SortOrderDTO,
} from '../generated'
import { mapToItemList } from '../items/mapper'
import { Item } from '../items/types'
import { buyoutStatus, mapToBuyout, mapToBuyoutList } from './mapper'
import { Buyout } from './types'

export const GetAll = async (
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
  sortKey?: string,
  sortOrder?: string,
): Promise<PaginatedData<Buyout>> => {
  const sorting =
    sortOrder == undefined ? undefined : sortOrder === 'asc' ? SortOrderDTO.ASC : SortOrderDTO.DESC
  const res = await client.getBuyouts({
    offset,
    limit,
    filterKey,
    filterValue,
    sortKey,
    sortOrder: sorting,
  })
  return {
    data: mapToBuyoutList(res.data ?? []),
    pagination: {
      totalRecords: res.pagination.total_records,
      page: res.pagination.page,
      perPage: res.pagination.per_page,
    },
  }
}

export const GetById = async (id: string): Promise<Buyout> => {
  const data = await client.getBuyout({ id })
  return mapToBuyout(data)
}

export const GetItems = async (
  id: string,
  offset?: number,
  limit?: number,
  filterKey?: Array<string>,
  filterValue?: Array<string>,
  sortKey?: string,
  sortOrder?: string,
): Promise<Item[]> => {
  const sorting =
    sortOrder == undefined ? undefined : sortOrder === 'asc' ? SortOrderDTO.ASC : SortOrderDTO.DESC
  const data = await client.getBuyoutItems({
    id,
    offset,
    limit,
    filterKey,
    filterValue,
    sortKey,
    sortOrder: sorting,
  })
  return mapToItemList(data)
}

export const GetRejectionOptions = async (): Promise<RejectionTypeDTO[]> => {
  const data = await client.getRejectionOptions()
  return data.data.sort((a, b) => {
    const nameA = a.description.toUpperCase() // ignore upper and lowercase
    const nameB = b.description.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    // names must be equal
    return 0
  })
}

export const Create = async (request: AddBuyoutDTO): Promise<BuyoutDTO> => {
  const data = client.addBuyout({ requestBody: request })
  return data
}

export const CreateItem = async (id: string, request: ItemDTO): Promise<void> => {
  await client.addBuyoutItem({ id, requestBody: request })
}

export const Patch = async (id: string, request: string): Promise<void> => {
  await client.updateBuyout({ id, requestBody: request })
}

export const Delete = async (id: string): Promise<void> => {
  await client.deleteBuyout({ id })
}

export const AcceptBuyout = async (id: string, offerType: SelectedOfferType): Promise<void> => {
  await client.acceptBuyout({ id, requestBody: { selected_offer_type: offerType } })
}

export const RejectBuyout = async (id: string, request: RejectDTO): Promise<void> => {
  await client.rejectBuyout({ id, requestBody: request })
}

export const ChangeStatus = async (id: string, nextStatus: string): Promise<void> => {
  switch (nextStatus) {
    case buyoutStatus.IN_REVIEW:
      await client.reviewBuyout({ id })
      break
    case buyoutStatus.SUBMITTED_FOR_CUSTOMER_APPROVAL:
      await client.submitBuyout({ id })
      break
    case buyoutStatus.COMPLETED:
      await client.completeBuyout({ id })
      break
    default:
      break
  }
}

export const SendItemToLightspeed = async (id: string): Promise<void> => {
  await client.sendItemsToLightspeed({ id })
}
